import { useState, useEffect } from 'react';
import { Route, useLocation, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import NavigationBar from './02_COMPONENTS/01_navbar/navbar.jsx';
import Loader from './02_COMPONENTS/09_loader/loader.jsx';
import Transitions from './02_COMPONENTS/08_transitions/transitions.jsx';

import './App.scss';

const Initial = lazy(() => import('./03_NAVIGATION/00_initial/initial.jsx'));
const Home = lazy(() => import('./03_NAVIGATION/01_home/home.jsx'));
const Hunting = lazy(() => import('./03_NAVIGATION/02_hunting/hunting.jsx'));
const Fishing = lazy(() => import('./03_NAVIGATION/03_fishing/fishing.jsx'));
const Nature = lazy(() => import('./03_NAVIGATION/04_nature/nature.jsx'));
const About = lazy(() => import('./03_NAVIGATION/05_about/about.jsx'));
const Contact = lazy(() => import('./03_NAVIGATION/06_contact/contact.jsx'));
const ThankYou = lazy(() => import('./03_NAVIGATION/07_thank-you/thank-you.jsx'));

function App() {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  
  useEffect(() => {
    const language = localStorage.getItem('language');
    
    if (language) {
      i18n.changeLanguage(language);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [i18n]);

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', process.env.REACT_APP_GA_ID, {
        page_path: location.pathname,
      });
    }
  }, [location]);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  if (loading) {
    return <Initial setLoading={setLoading} />;
  }

  return (
    <div className="App">
      <div className='lapponia-silent'>
        <NavigationBar />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/hunting" element={<Hunting />} />
            <Route path="/fishing" element={<Fishing />} />
            <Route path="/nature" element={<Nature />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/thank-you" element={<ThankYou />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}

export default App;
